import { generateTiles } from "./SaveBoard";

type HiddenType = { row: number; col: number };

export default function GenerateBoard(diff: string = "easy") {
  let sol: number[][] = createValidBoard();

  let difficulties: Map<string, number[]> = new Map();

  difficulties.set("easy", [34, 37]);
  difficulties.set("medium", [39, 42]);
  difficulties.set("hard", [43, 46]);

  /*
    easy: 38, 30
    med: 55, 40
    hard: 59, 46
  */

  const currDiff = difficulties.get(diff.toLowerCase()) ?? [34, 46];
  const numHidden =
    currDiff[0] + Math.floor(Math.random() * (currDiff[1] - currDiff[0]));
  let hidden: HiddenType[] = hideSquares(sol, [], [], 8 ?? numHidden);
  let shown: number[][] = [];

  for (let i = 0; i < 9; i++) {
    shown.push([]);
    for (let j = 0; j < 9; j++) {
      shown[i].push(j);
    }
  }

  for (let i = 0; i < hidden.length; i++) {
    shown[hidden[i].row].splice(shown[hidden[i].row].indexOf(hidden[i].col), 1);
  }

  const looseGame = generateTiles(sol, shown);
  return {
    board: {
      tiles: looseGame.tiles,
      values: sol,
      given: shown,
      size: sol.length,
      remainingTiles: looseGame.remainingTiles,
      countNums: looseGame.countNums,
      difficulty: diff,
    },
    complete: false,
    errors: 0,
  };
}

function checkBoard(b: number[][]) {
  for (let i = 0; i < b.length; i++) {
    for (let j = 0; j < b[i].length; j++) {
      if (!checkSquare(b, b[i][j], i, j)) return false;
    }
  }

  return true;
}

function createValidBoard() {
  let b = [];
  do {
    b = Array.from({ length: 9 }, () => Array.from({ length: 9 }, () => 0));

    makeValidSquare(b, 1, 0);
    // const out = makeValidSquareSafe(b, 1, 0, 0, 300);
  } while (!checkBoard(b));

  return b;
}

function makeValidSquare(b: number[][], num: number, sqr: number) {
  // Last square is solved
  if (num > 9) return true;

  let openSpots = [];
  for (let i = 0; i < 9; i++) {
    let row = Math.floor(sqr / 3) * 3 + Math.floor(i / 3);
    let col = (sqr % 3) * 3 + (i % 3);
    if (b[row][col] === 0) {
      openSpots.push(i);
    }
  }

  while (openSpots.length > 0) {
    const currSpot = openSpots.splice(
      Math.floor(Math.random() * openSpots.length),
      1
    )[0];

    let row = Math.floor(sqr / 3) * 3 + Math.floor(currSpot / 3);
    let col = (sqr % 3) * 3 + (currSpot % 3);

    if (checkSquareBuild(b, num, row, col)) {
      b[row][col] = num;

      let nextNum = num;
      let nextSqr = sqr;
      if (sqr < 8) {
        nextSqr++;
      } else {
        nextSqr = 0;
        nextNum++;
      }

      if (makeValidSquare(b, nextNum, nextSqr)) {
        return true;
      } else {
        b[row][col] = 0;
      }
    }
  }

  return false;
}

function checkSquareBuild(
  board: number[][],
  num: number,
  r: number,
  c: number
) {
  for (let i = 0; i < Math.floor(r / 3) * 3; i++) {
    if (board[i][c] === num) return false;
  }

  for (let i = 0; i < Math.floor(c / 3) * 3; i++) {
    if (board[r][i] === num) return false;
  }

  return true;
}

function checkSquare(board: number[][], num: number, r: number, c: number) {
  const sqrRowStart = Math.floor(r / 3) * 3;
  const sqrColStart = Math.floor(c / 3) * 3;

  for (let i = sqrRowStart; i < sqrRowStart + 3; i++) {
    for (let j = sqrColStart; j < sqrColStart + 3; j++) {
      if (i === r && j === c) continue;
      if (board[i][j] === num && board[i][j] > 0) return false;
    }
  }

  for (let i = 0; i < sqrColStart; i++) {
    if (board[r][i] === num && board[r][i] > 0) return false;
  }
  for (let i = sqrColStart + 3; i < 9; i++) {
    if (board[r][i] === num && board[r][i] > 0) return false;
  }

  for (let i = 0; i < sqrRowStart; i++) {
    if (board[i][c] === num && board[i][c] > 0) return false;
  }
  for (let i = sqrRowStart + 3; i < 9; i++) {
    if (board[i][c] === num && board[i][c] > 0) return false;
  }

  return true;
}

function numSolutions(b: number[][], hidden: HiddenType[], currIdx: number) {
  let hiddenBoard = [];
  if(currIdx === 0) {
    hiddenBoard = makeHidden(b, hidden);
  } else {
    hiddenBoard = b;
  }

  let total = 0;

  if(currIdx > hidden.length - 1) return 1;

  for(let i = 0; i < 9; i++) {
    if(checkSquare(hiddenBoard, i + 1, hidden[currIdx].row, hidden[currIdx].col)) {
      hiddenBoard[hidden[currIdx].row][hidden[currIdx].col] = i + 1;
      total += numSolutions(hiddenBoard, hidden, currIdx + 1);
      hiddenBoard[hidden[currIdx].row][hidden[currIdx].col] = 0;
    }
  }

  return total;
}

/*
Must have at least 17 clues to be solvable
So no more than 64 hidden
*/
function hideSquares(board: number[][], hidden: HiddenType[], shownSpots: number[], n: number) : HiddenType[] {
  if(hidden.length === n) return hidden;

  if(hidden.length === 0) {
    shownSpots = [];
    for(let i = 0; i < 9; i++) {
      for(let j = 0; j < 9; j++) {
        shownSpots.push((i * 9) + j);
      }
    }
  }

  while(shownSpots.length > 0) {
    const currVal = shownSpots.splice(Math.floor(Math.random() * shownSpots.length), 1)[0];
    hidden.push({row: Math.floor(currVal / 9), col: currVal % 9});

    if(numSolutions(board, hidden, 0) === 1) {
      let hiddenOut: HiddenType[] = hideSquares(board, hidden, shownSpots, n);
      if(hiddenOut.length === n) return hiddenOut;
    }
    hidden.pop();
  }

  return hidden;
}

function chooseHidden(n: number) {
  let hidden: HiddenType[] = [];

  for (let i = 0; i < n; i++) {
    let row = 0;
    let col = 0;
    let isNew = false;
    while (!isNew) {
      isNew = true;

      row = Math.floor(Math.random() * 9);
      col = Math.floor(Math.random() * 9);

      for (let n = 0; n < hidden.length; n++) {
        isNew = !(hidden[n].row === row && hidden[n].col === col);
        if (!isNew) break;
      }

      if (isNew) hidden.push({ row: row, col: col });
    }
  }

  return hidden;
}

function makeHidden(b: number[][], hidden: HiddenType[]) {
  let board = [];
  for (let i = 0; i < b.length; i++) {
    board.push([...b[i]]);
  }

  for (let i = 0; i < hidden.length; i++) {
    board[hidden[i].row][hidden[i].col] = 0;
  }

  return board;
}

function customHidden(show: number[][]) {
  let hidden = [];
  for (let i = 0; i < 9; i++) {
    for (let j = 0; j < 9; j++) {
      let hide = true;
      for (let n = 0; n < show.length; n++) {
        if (show[n][0] === i && show[n][1] === j) {
          hide = false;
          break;
        }
      }
      if (hide) hidden.push({ row: i, col: j });
    }
  }

  return hidden;
}

function printBoard(b: number[][]) {
  console.log(" ");
  for (let i = 0; i < b.length; i++) {
    let row = "";
    for (let j = 0; j < b[i].length; j++) {
      row +=
        (j === 0 ? "" : j % 3 === 0 ? " | " : " ") +
        (b[i][j] === 0 ? "*" : b[i][j]);
    }
    if (i > 0 && i % 3 === 0) console.log("------+-------+------");
    console.log(row);
  }
  console.log(" ");
}
