import { client } from "./db";
import { boardType, gameType, tileType } from "../Components/Types";
import axios from "axios";
import { useState } from "react";
import { generateTiles } from "./SaveBoard";

export default async function FetchBoard() {
  let out = await client.execute("SELECT * FROM boards");
  const data = out.rows[Math.floor(Math.random() * out.rows.length)];

  let g: number[][] = [];
  let givenSplit = ("" + data.given).split(",");
  for (let i = 0; i < givenSplit.length; i++) {
    g.push([]);
    const nums = givenSplit[i].split("");
    for (let j = 0; j < nums.length; j++) {
      g[i].push(parseInt(nums[j]));
    }
  }

  let t: tileType[][] = [];
  let v: number[][] = [];
  let n: number[] = [];

  let rows: string[] = ("" + data.vals).split(",");
  let remainingTiles = rows.length * rows.length;

  for (let i = 0; i < rows.length; i++) {
    n.push(rows.length - 1);
  }

  for (let i = 0; i < rows.length; i++) {
    t.push([]);
    v.push([]);
    const nums = rows[i].split("");
    for (let j = 0; j < nums.length; j++) {
      const num = parseInt(nums[j]);
      t[i].push({
        value: num,
        key: "" + i + j,
        init: g[i].includes(j + 1),
        complete: g[i].includes(j + 1),
        row: i,
        col: j,
        notes: [],
      });
      if (g[i].includes(j + 1)) {
        remainingTiles--;
        n[num - 1]--;
      }
      v[i].push(num);
    }
  }

  const b: boardType = {
    tiles: t,
    values: v,
    remainingTiles: remainingTiles,
    size: v.length,
    given: g,
    countNums: n,
    difficulty: "0"
  };

  return b;
}

type requestType = {
  value: any;
  solution: any;
  difficulty: string;
};

async function newBoard(diff: string) {
  let data: requestType = { value: null, solution: null, difficulty: "" };
  let n = 0;
  while (n < 100 && data.difficulty !== diff) {
    data = await getDataAxios();
  }
  return data;
}

export async function onlineFetch(diff: string) {
  // let data = await getDataAxios();
  let data: requestType;

  if (diff === "Easy") {
    data = await newBoard("Medium");
  } else {
    data = await newBoard(diff);
  }

  let valsIn = data.value;
  let solIn = data.solution;

  let giv: number[][] = [];
  for (let i = 0; i < valsIn.length; i++) {
    giv.push([]);
    for (let j = 0; j < valsIn[i].length; j++) {
      if (valsIn[i][j] > 0) giv[i].push(j);
    }
  }

  if (diff === "Easy") giv = makeEasier(giv);

  let g: gameType = toGame(solIn, valsIn, giv, diff);
  return g;
}

async function getDataAxios() {
  try {
    const response = await axios.get(
      "https://sudoku-api.vercel.app/api/dosuku"
    );

    return response.data.newboard.grids[0];
  } catch {
    return null;
  }
}

function makeEasier(v: number[][]) {
  let numChanges = 15 + Math.floor(Math.random() * 5);
  let n = 4000;

  while (n > 0 && numChanges > 0) {
    const row = Math.floor(Math.random() * 9);
    const col = Math.floor(Math.random() * 9);
    n--;
    if (!v[row].includes(col)) {
      v[row].push(col);
      numChanges--;
    }
  }

  return v;
}

export function defaultGame(): gameType {
  const sol: number[][] = [
    [1, 7, 8, 3, 4, 5, 9, 6, 2],
    [2, 9, 4, 6, 8, 1, 3, 5, 7],
    [3, 5, 6, 7, 2, 9, 1, 4, 8],
    [9, 8, 1, 4, 7, 6, 2, 3, 5],
    [6, 4, 3, 8, 5, 2, 7, 9, 1],
    [5, 2, 7, 9, 1, 3, 6, 8, 4],
    [4, 3, 2, 1, 9, 8, 5, 7, 6],
    [8, 6, 5, 2, 3, 7, 4, 1, 9],
    [7, 1, 9, 5, 6, 4, 8, 2, 3],
  ];
  const shown: number[][] = [
    [2, 3, 4, 5, 7],
    [0, 1, 2, 3, 4, 5, 6, 7, 8],
    [1, 4, 6, 7, 8],
    [4],
    [0, 1, 3, 4, 6],
    [1, 3, 7, 8],
    [0, 1, 2, 5, 6, 7],
    [0, 1],
    [0, 2, 3, 4, 6, 7],
  ];

  const looseGame = generateTiles(sol, shown);
  return {
    board: {
      tiles: looseGame.tiles,
      values: sol,
      given: shown,
      size: sol.length,
      remainingTiles: looseGame.remainingTiles,
      countNums: looseGame.countNums,
      difficulty: "Medium"
    },
    complete: false,
    errors: 0
  }
}

export function GetDiff(difficulty: string) {
  const [diff, setDiff] = useState("");

  async function newBoard() {
    let diff = "";
    let data = null;
    let n = 0;
    while (n < 100 && diff !== difficulty) {
      data = await getDataAxios();
      diff = data.difficulty;
      setDiff(diff);
    }
  }

  return (
    <>
      <button onClick={newBoard}>New</button>
      Diff: {diff}
    </>
  );
}

// TODO make this better
export function toGame(
  sol: number[][],
  valsIn: number[][],
  giv: number[][],
  difficulty: string
) {
  let tiles: tileType[][] = [];
  let remainingTiles = sol.length * sol.length;
  let countNums = [];
  for (let i = 0; i < 9; i++) {
    countNums.push(9);
  }

  for (let i = 0; i < sol.length; i++) {
    tiles.push([]);
    for (let j = 0; j < sol.length; j++) {
      tiles[i].push({
        key: "" + i + j,
        init: giv[i].includes(j),
        value: sol[i][j],
        complete: giv[i].includes(j),
        row: i,
        col: j,
        notes: [],
      });
      if (giv[i].includes(j)) {
        remainingTiles--;
        countNums[sol[i][j] - 1]--;
      }
    }
  }

  

  let b: boardType = {
    tiles: tiles,
    values: sol,
    given: giv,
    size: sol.length,
    remainingTiles: remainingTiles,
    countNums: countNums,
    difficulty: difficulty
  };

  const g: gameType = {
    board: b,
    complete: false,
    errors: 0,
  };

  return g;
}
