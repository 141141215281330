import { useAuth } from "@clerk/clerk-react";
import { client, db } from "./db";
import { gameType, tileType } from "../Components/Types";
import { game } from "../Components/Game";
import {
  InsertSavedGame,
  InsertUser,
  SelectSavedGame,
  SelectUser,
  savedGames,
  users,
} from "./schema";
import { eq } from "drizzle-orm";

function gameToString(g: gameType, uid: string) {
  let s = "";
  let b = "";
  for (let i = 0; i < g.board.size; i++) {
    for (let j = 0; j < g.board.size; j++) {
      b += g.board.values[i][j];
    }
    b += i < g.board.size - 1 ? "," : "";
  }
}

async function addCol() {
  // board, shown, complete, errors, size, difficulty
  client.execute("ALTER TABLE saved_games ADD errors INT");
  // " ADD size INT ADD complete BOOL ADD errors INT ADD difficulty TEXT"
}

function strToIntTable(shown: string) {
  let s: number[][] = [];
  const arr = shown.split(",");

  for (let i = 0; i < arr.length; i++) {
    const nums = arr[i].split("");
    s.push([]);
    for (let j = 0; j < nums.length; j++) {
      s[i].push(parseInt(nums[j]));
    }
  }

  return s;
}

type dbSavedGamesType = {};

async function insertUser(data: InsertUser): Promise<void> {
  await db.insert(users).values(data);
}

async function getUser(id: SelectUser["id"]) {
  const user = await db.select().from(users).where(eq(users.id, id));

  return user;
}

async function updateUser(
  id: SelectUser["id"],
  data: Partial<Omit<SelectUser, "id">>
) {
  const updatedUser = await db
    .update(users)
    .set(data)
    .where(eq(users.id, id))
    .returning();
}

async function deleteUser(id: SelectUser["id"]): Promise<void> {
  await db.delete(users).where(eq(users.id, id));
}

async function insertGame(data: InsertSavedGame) {
  await db.insert(savedGames).values(data);
}

async function getGame(id: SelectSavedGame["uid"]) {
  const game = await db.select().from(savedGames).where(eq(savedGames.uid, id));

  return game;
}

async function updateGame(
  id: SelectSavedGame["uid"],
  data: Partial<Omit<SelectSavedGame, "uid">>
) {
  await db
    .update(savedGames)
    .set(data)
    .where(eq(savedGames.uid, id))
    .returning();
}

export function emptyNotes(size: number): boolean[][][] {
  let notes: boolean[][][] = Array(size).fill(
    Array(size).fill(Array(size).fill(false))
  );

  return notes;
}

// Generates tiles
export function generateTiles(
  values: number[][],
  shown: number[][],
  notes: boolean[][][] | null = null
): { tiles: tileType[][]; remainingTiles: number; countNums: number[] } {
  let tiles: tileType[][] = [];
  let remainingTiles = values.length * values.length;
  let countNums: number[] = Array(9).fill(9);

  if (!notes) notes = emptyNotes(values.length);

  for (let i = 0; i < values.length; i++) {
    tiles.push([]);
    for (let j = 0; j < values.length; j++) {
      const show = shown[i].includes(j);
      tiles[i].push({
        key: "" + i + j,
        init: show,
        value: values[i][j],
        complete: show,
        row: i,
        col: j,
        notes: notes[i][j],
      });
      if (show) {
        remainingTiles--;
        countNums[values[i][j] - 1]--;
      }
    }
  }

  return { tiles: tiles, remainingTiles: remainingTiles, countNums: countNums };
}

function dbToGame(g: SelectSavedGame): gameType {
  const values = strToIntTable(g.game!);
  const shown = strToIntTable(g.shown!);
  const notes = notesFromStr(g.notes);
  const board = generateTiles(values, shown, notes);

  return {
    board: {
      tiles: board.tiles,
      values: values,
      given: shown,
      size: g.size!,
      remainingTiles: board.remainingTiles,
      countNums: board.countNums,
      difficulty: g.difficulty!,
    },
    complete: g.complete!,
    errors: g.errors!,
  };
}

export async function getSavedBoard(uid: string): Promise<gameType | null> {
  // const f = await client.execute({
  //   sql: "SELECT * FROM saved_games WHERE uid = ? LIMIT 1;",
  //   args: [uid],
  // });

  const saved = await getGame(uid);

  const hasSaved = saved.length > 0;

  if (hasSaved) return dbToGame(saved[0]);

  return null;
}

export function tableToString(t: any[][]) {
  let str = "";
  for (let i = 0; i < t.length; i++) {
    for (let j = 0; j < t[i].length; j++) {
      str += t[i][j];
    }
    if (i < t.length - 1) str += ",";
  }

  return str;
}

function notesToStr(tiles: tileType[][]): string {
  let n = "";

  for (let i = 0; i < game.board.size; i++) {
    for (let j = 0; j < game.board.size; j++) {
      const notes = game.board.tiles[i][j].notes;
      for (let k = 0; k < notes.length; k++) {
        if (notes[k]) n += k + 1;
      }
      if (j < game.board.size - 1) n += ",";
    }
    if (i < game.board.size - 1) n += ";";
  }

  return n;
}

function notesFromStr(notesStr: string | null): boolean[][][] {
  if (!notesStr || notesStr === "") return emptyNotes(9);

  let notes: boolean[][][] = [];

  const rows = notesStr.split(";");
  for (let i = 0; i < rows.length; i++) {
    notes.push([]);
    const cols = rows[i].split(",");
    for (let j = 0; j < cols.length; j++) {
      notes[i].push([]);
      const nums = cols[j].split("");
      // TODO figure this out - ot works with the + 1 but i don't know why
      for (let k = 0; k < rows.length; k++) {
        notes[i][j].push(nums.includes("" + (k + 1)));
      }
    }
  }

  return notes;
}

export async function saveBoard(uid: string, game: gameType) {
  // board, shown, complete, errors, size, difficulty

  const board = tableToString(game.board.values);
  const shown = tableToString(game.board.given);
  const notes = notesToStr(game.board.tiles);

  const f = await getGame(uid);
  //  "SELECT 1 FROM saved_games WHERE uid = ? LIMIT 1;"

  const hasSaved = f.length > 0;

  if (hasSaved) {
    // Save data to row
    // await client.execute({
    //   sql: "UPDATE saved_games SET game = ?, shown = ?, size = ?, complete = ?, errors = ?, difficulty = ?  WHERE uid = ?",
    //   args: [
    //     b,
    //     s,
    //     game.board.size,
    //     game.complete,
    //     game.errors,
    //     game.board.difficulty,
    //     uid,
    //   ],
    // });
    updateGame(uid, {
      game: board,
      shown: shown,
      size: game.board.size,
      complete: game.complete,
      errors: game.errors,
      difficulty: game.board.difficulty,
      notes: notes,
    });
  } else {
    // Create row with data
    // await client.execute({
    //   sql: "INSERT INTO saved_games VALUES (?, ?, ?, ?, ?, ?, ?);",
    //   args: [
    //     uid,
    //     b,
    //     s,
    //     game.board.size,
    //     game.complete,
    //     game.errors,
    //     game.board.difficulty,
    //   ],
    // });
    insertGame({
      uid: uid,
      game: board,
      shown: shown,
      size: game.board.size,
      complete: game.complete,
      errors: game.errors,
      difficulty: game.board.difficulty,
      notes: notes,
    });
  }
}

export function SaveBoardTest() {
  const uid = useAuth().userId!;

  return (
    <>
      <button onClick={() => getGame(uid)}>Drizzle</button>
      <button onClick={() => saveBoard(uid, game)}>Save</button>
      <button onClick={() => getSavedBoard(uid)}>Get Saved</button>
      <button onClick={() => gameToString(game, uid)}>Game to String</button>
    </>
  );
}
