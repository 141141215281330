import { integer, sqliteTable, text } from "drizzle-orm/sqlite-core";

export const users = sqliteTable("users", {
  id: integer("ID").primaryKey(),
  name: text("name").notNull(),
});

export const savedGames = sqliteTable("saved_games", {
  uid: text("uid").primaryKey(),
  game: text("game"),
  shown: text("shown"),
  size: integer("size"),
  complete: integer("complete", {mode: "boolean"}),
  errors: integer("errors").default(0),
  difficulty: text("difficulty"),
  notes: text("notes")
});

export type InsertSavedGame = typeof savedGames.$inferInsert;
export type SelectSavedGame = typeof savedGames.$inferSelect;

// timestamp3: timestamp('timestamp3').defaultNow()

// export const posts = sqliteTable('posts', {
//   id: integer('id').primaryKey(),
//   title: text('title').notNull(),
//   content: text('content').notNull(),
//   userId: integer('user_id')
//     .notNull()
//     .references(() => users.id, { onDelete: 'cascade' }),
//   createdAt: text('created_at')
//     .default(sql`CURRENT_TIMESTAMP`)
//     .notNull(),
// });

export type InsertUser = typeof users.$inferInsert;
export type SelectUser = typeof users.$inferSelect;

// export type InsertPost = typeof posts.$inferInsert;
// export type SelectPost = typeof posts.$inferSelect;
