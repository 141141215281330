import { useEffect, useState } from "react";
import TestDB from "../db/TestDB";
import { gameType, tileType } from "./Types";
import { defaultGame, onlineFetch } from "../db/FetchBoard";
import { getSavedBoard, saveBoard } from "../db/SaveBoard";
import { useAuth } from "@clerk/clerk-react";
import GenerateBoard from "../db/GenerateBoard";

// const boardNums: any[] = [
//   [6, 2, 7, 9, 8, 3, 4, 1, 5],
//   [3, 8, 1, 4, 5, 6, 9, 2, 7],
//   [9, 5, 4, 1, 7, 2, 8, 3, 6],
//   [5, 9, 8, 7, 6, 1, 2, 4, 3],
//   [7, 3, 2, 5, 4, 8, 6, 9, 1],
//   [1, 4, 6, 3, 2, 9, 7, 5, 8],
//   [8, 6, 5, 2, 1, 4, 3, 7, 9],
//   [4, 1, 9, 8, 3, 7, 5, 6, 2],
//   [2, 7, 3, 6, 9, 5, 1, 8, 4],
// ];

// const given = [
//   [1, 3, 4, 6, 7, 9],
//   [2, 3, 4, 6, 7, 8],
//   [3, 4, 5, 6, 7],
//   [2, 3, 4, 6, 7, 8],
//   [2, 3, 4, 6, 7, 8],
//   [2, 3, 4, 6, 7, 8],
//   [3, 4, 5, 6, 7],
//   [2, 3, 4, 6, 7, 8],
//   [1, 3, 4, 6, 7, 9],
// ];

export let game: gameType = {
  board: {
    tiles: [],
    values: [],
    given: [],
    size: 9,
    remainingTiles: 0,
    difficulty: "1",
    countNums: [],
  },
  complete: false,
  errors: 0,
};

// const givenAll = [
//   [2, 3, 4, 5, 6, 7, 8, 9],
//   [1, 2, 3, 4, 5, 6, 7, 8, 9],
//   [1, 2, 3, 4, 5, 6, 7, 8, 9],
//   [1, 2, 3, 4, 5, 6, 7, 8, 9],
//   [1, 2, 3, 4, 5, 6, 7, 8, 9],
//   [1, 2, 3, 4, 5, 6, 7, 8, 9],
//   [1, 2, 3, 4, 5, 6, 7, 8, 9],
//   [1, 2, 3, 4, 5, 6, 7, 8, 9],
//   [1, 2, 3, 4, 5, 6, 7, 8, 9],
// ];

export default function Game() {
  const [gameBoard, setGameBoard] = useState<tileType[][]>();
  const [focus, setFocus] = useState(0);
  const [updateBoard, setUpdateBoard] = useState(1);
  const [noteMode, setNoteMode] = useState(false);
  const [focusedTile, setFocusedTile]: [tileType | null, any] = useState(null);
  const [diff, setDiff] = useState("Easy");
  const [autoSave, setAutoSave] = useState(true);
  const [section, setSection] = useState(-1);

  const nums: number[] = Array.from({ length: 9 }, (_, i) => i + 1);

  // function sendBoard(b: boardType) {
  //   let vals = "";
  //   let given = "";

  //   for (let i = 0; i < b.size; i++) {
  //     for (let j = 0; j < b.size; j++) {
  //       vals += b.values[i][j];
  //     }
  //     vals += ",";
  //   }

  //   for (let i = 0; i < b.given.length; i++) {
  //     for (let j = 0; j < b.given[i].length; j++) {
  //       given += b.given[i][j];
  //     }
  //     given += ",";
  //   }

  //   console.log(vals);
  //   console.log(given);

  //   client.execute({
  //     sql: "INSERT INTO boards VALUES (?, ?, ?, ?);",
  //     args: [null, vals, given, b.difficulty],
  //   });
  // }

  // function initBoard(size: number, boardNums: number[][], given: number[][]) {
  //   let b: boardType = {
  //     tiles: [],
  //     values: boardNums,
  //     given: given,
  //     size: size,
  //     remainingTiles: size * size,
  //     countNums: [],
  //     difficulty: "1",
  //   };
  //   let s: tileType[][] = [];

  //   for (let i = 0; i < size; i++) {
  //     b.countNums.push(b.size);
  //   }

  //   for (let i = 0; i < 9; i++) {
  //     s.push([]);
  //     for (let j = 0; j < 9; j++) {
  //       s[i].push({
  //         key: "" + i + j,
  //         init: false,
  //         value: boardNums[i][j],
  //         complete: false,
  //         row: i,
  //         col: j,
  //         notes: [],
  //       });
  //     }
  //   }

  //   for (let i = 0; i < given.length; i++) {
  //     for (let j = 0; j < given[i].length; j++) {
  //       b.remainingTiles--;
  //       s[i][given[i][j] - 1].init = true;
  //       s[i][given[i][j] - 1].complete = true;
  //     }
  //   }
  //   b.tiles = s;

  //   return b;
  // }

  const uid = useAuth().userId!;

  const getNewGame = async () => await onlineFetch(diff);

  // async function getBoard(loc: number = 1) {
  //   const emptyTile: tileType = {
  //     key: "",
  //     init: false,
  //     value: 0,
  //     complete: false,
  //     row: 0,
  //     col: 0,
  //     notes: [],
  //   };
  //   const emptyBoard: tileType[][] = [];
  //   for (let i = 0; i < 9; i++) {
  //     emptyBoard.push([]);
  //     for (let j = 0; j < 9; j++) {
  //       emptyBoard[i].push(emptyTile);
  //     }
  //   }
  //   game.board.tiles = emptyBoard;

  //   // setGameBoard(game.board.tiles);

  //   let boardLoc = "choose";

  //   if (boardLoc === "db") {
  //     const b: boardType = await FetchBoard();
  //     game = {
  //       board: b,
  //       complete: false,
  //       errors: 0,
  //     };
  //   } else if (boardLoc === "online") {
  //     game = await onlineFetch(diff);
  //   } else if (boardLoc === "choose") {
  //     game = (await getSavedBoard(uid)) ?? (await onlineFetch(diff));
  //   }

  //   setGameBoard(game.board.tiles);
  // }

  async function setGameIfSaved() {
    game = (await getSavedBoard(uid)) ?? (await getNewGame()) ?? defaultGame();

    setGameBoard(game.board.tiles);
  }

  async function setNewGame() {
    // game = (await getNewGame()) ?? defaultGame();

    game = GenerateBoard(diff);
    setGameBoard(game.board.tiles);
  }

  async function setSaved() {
    const saved = await getSavedBoard(uid);
    if (saved) {
      game = saved;
      setGameBoard(game.board.tiles);
    }
  }

  const onKeyPress = (key: string) => {
    const num = parseInt(key);
    if (!!num) {
      selectNum(num);
    }
  };

  useEffect(() => {
    setGameIfSaved();

    // game = {
    //   board: initBoard(9, boardNums, given),
    //   complete: false,
    //   errors: 0,
    // };
  }, []);

  // function tileClick(t: tileType) {
  //   if (t.complete) {
  //     setFocus(t.value === focus ? 0 : t.value);
  //     return;
  //   }

  //   if (focus > 0 && noteMode) {
  //     t.notes[focus] = false

  //     return;
  //   }

  //   if (focus === t.value) {
  //     game.board.tiles[t.row][t.col].complete = true;
  //     game.board.remainingTiles--;
  //     if (game.board.remainingTiles === 0) {
  //       setComplete(true);
  //     }
  //     setGameBoard(game.board.tiles);
  //     setUpdateBoard(updateBoard * -1);
  //   } else {
  //     game.errors++;
  //     setErrorCount(errorCount + 1);
  //     setFocus(0);
  //   }
  // }

  function clearNumNotes(n: number) {
    for (let i = 0; i < game.board.size; i++) {
      for (let j = 0; j < game.board.size; j++) {
        if (game.board.tiles[i][j].notes[n - 1]) {
        }
      }
    }
  }

  async function update() {
    setGameBoard(game.board.tiles);
    if (autoSave) saveBoard(uid, game);
  }

  function chooseTile(t: tileType, index: number = -1) {
    if (t.complete) {
      if (t.value === focus) {
        setFocus(0);
      } else if (game.board.countNums[t.value - 1] > 0) {
        setFocus(t.value);
        setFocusedTile(null);
        setSection(-1);
      }

      return;
    }
    setFocus(0);

    if (focusedTile && focusedTile.key === t.key) {
      setFocusedTile(null);
      setSection(-1);
    } else {
      setFocusedTile(t);
      setSection(index);
    }
  }

  function selectNum(n: number) {
    if (noteMode) {
      if (!focusedTile) {
        focus === n ? setFocus(0) : setFocus(n);
        return;
      }
      focusedTile.notes[n - 1] = !focusedTile.notes[n - 1];
      setGameBoard(game.board.tiles);
      update();

      // TODO figure this out - it wont refresh without this
      setUpdateBoard(updateBoard * -1);

      return;
    }

    if (game.board.countNums[n - 1] === 0) return;

    if (focusedTile) {
      if (focusedTile.value === n) {
        focusedTile.complete = true;
        removeTileNotes(focusedTile.row, focusedTile.col);
        setFocusedTile(null);
        setSection(-1);
        game.board.remainingTiles--;
        game.board.given[focusedTile.row].push(focusedTile.col);
        game.board.countNums[n - 1]--;
        if (game.board.countNums[n - 1] > 0) {
          setFocus(n);
        } else {
          removeAllNotes(n);
        }
        if (game.board.remainingTiles === 0) {
          game.complete = true;
        }
      } else {
        game.errors++;
        setFocusedTile(null);
        setSection(-1);
      }
      update();
    } else {
      if (focus === n) {
        setFocus(0);
      } else {
        if (game.board.countNums[n - 1] > 0) {
          setFocus(n);
        }
      }
    }
  }

  function sectionNum(row: number, col: number) {
    return Math.floor(row / 3) * 3 + Math.floor(col / 3);
  }

  function removeTileNotes(row: number, col: number) {
    const num = game.board.tiles[row][col].value;
    const secNum = sectionNum(row, col);
    for (let i = 0; i < game.board.size; i++) {
      for (let j = 0; j < game.board.size; j++) {
        const t = game.board.tiles[i][j];
        if (
          t.row === row ||
          t.col === col ||
          sectionNum(t.row, t.col) === secNum
        ) {
          t.notes[num - 1] = false;
        }
      }
    }
  }

  function removeAllNotes(num: number) {
    for (let i = 0; i < game.board.size; i++) {
      for (let j = 0; j < game.board.size; j++) {
        game.board.tiles[i][j].notes[num - 1] = false;
      }
    }
  }

  function changeDiff() {
    const diffs = ["Easy", "Medium", "Hard"];
    setDiff(diffs[(diffs.indexOf(diff) + 1) % 3]);
  }

  return (
    <>
      {/* <GetDiff difficulty="Easy"/> */}
      {/* <button onClick={() => sendBoard(game.board)}>send</button> */}
      {/* {game.complete && <div>Done!!</div>} */}

      <div className="game" onKeyDown={(k) => onKeyPress(k.key)}>
        <div className="title-container">
          <div className="title">Sudoku</div>
        </div>
        <div className="divider" />
        <Board
          gameBoard={gameBoard!}
          updateBoard={updateBoard}
          focus={focus}
          focusedTile={focusedTile}
          chooseTile={chooseTile}
          nums={nums}
          section={section}
          selectNum={selectNum}
        />
        <Numbers
          focus={focus}
          setFocus={setFocus}
          selectNum={selectNum}
          nums={nums}
        />
        <div className="note-mode">
          <button onClick={() => setNoteMode(!noteMode)}>
            {noteMode ? "Numbers" : "Notes"}
          </button>
        </div>
      </div>
      <Info
        changeDiff={changeDiff}
        diff={diff}
        setNewGame={setNewGame}
        uid={uid}
        setAutoSave={setAutoSave}
        autoSave={autoSave}
        setSaved={setSaved}
      />

      {false && process.env.NODE_ENV === "development" && <TestDB />}
    </>
  );
}

function Info({
  changeDiff,
  diff,
  setNewGame,
  uid,
  setAutoSave,
  autoSave,
  setSaved,
}: {
  changeDiff: () => void;
  diff: string;
  setNewGame: () => void;
  uid: string;
  setAutoSave: any;
  autoSave: boolean;
  setSaved: () => void;
}) {
  return (
    <div className="top">
      <div className="info">
        <div>Errors: {game.errors}</div>
        <div className="inline-divider" />
        <div>Difficulty: {game.board.difficulty}</div>
      </div>
      <div className="divider" />
      <div className="menu">
        <button onClick={changeDiff}>{diff}</button>
        <button onClick={setNewGame}>New Game</button>
        <div className="save-options">
          <button onClick={() => saveBoard(uid, game)}>Save Game</button>
          Autosave:
          <button onClick={() => setAutoSave(!autoSave)}>
            {autoSave ? "on" : "off"}
          </button>
          <button onClick={setSaved}>Get Saved</button>
        </div>
      </div>
    </div>
  );
}

function Board({
  gameBoard,
  updateBoard,
  focus,
  focusedTile,
  chooseTile,
  nums,
  section,
  selectNum,
}: {
  gameBoard: tileType[][];
  updateBoard: number;
  focus: number;
  focusedTile: tileType | null;
  chooseTile: (t: tileType) => void;
  nums: number[];
  section: number;
  selectNum: (n: number) => void;
}) {
  return (
    <div className="board">
      {gameBoard &&
        updateBoard &&
        gameBoard.map((s, index: number) => (
          <div key={index}>
            <Section
              grid={gameBoard[Math.floor(index / 3) * 3]
                .slice((index % 3) * 3, (index % 3) * 3 + 3)
                .concat(
                  gameBoard[Math.floor(index / 3) * 3 + 1].slice(
                    (index % 3) * 3,
                    (index % 3) * 3 + 3
                  ),
                  gameBoard[Math.floor(index / 3) * 3 + 2].slice(
                    (index % 3) * 3,
                    (index % 3) * 3 + 3
                  )
                )}
              focus={focus}
              focusedTile={focusedTile}
              chooseTile={chooseTile}
              nums={nums}
              index={index}
              section={section}
              selectNum={selectNum}
            />
          </div>
        ))}
    </div>
  );
}

const boxShadow = false;

function Section({
  grid,
  focus,
  focusedTile,
  chooseTile,
  nums,
  index,
  section,
  selectNum,
}: any) {
  // const display = (e: tile) => {
  //   if (e.init) return e.value;
  //   return e.complete ? e.value : "";
  // };

  function getName(t: tileType) {
    let name = "notes";
    if (focusedTile && t.key === focusedTile.key) {
      name += " focused";
    } else if (
      focusedTile &&
      (focusedTile.row === t.row ||
        focusedTile.col === t.col ||
        index === section)
    ) {
      name += " side";
    }
    return name;
  }

  return (
    <div className="section">
      {grid.map((e: tileType) => (
        <div key={e.key}>
          {e.complete ? (
            <button
              style={{
                backgroundColor: game.board.countNums[e.value - 1]
                  ? `var(--n${e.value})`
                  : "transparent",
                color: !game.board.countNums[e.value - 1]
                  ? `var(--n${e.value})`
                  : "white",
                boxShadow: game.complete
                  ? `0 0 .4em .2em gold`
                  : boxShadow && e.value === focus
                  ? `0 0 1.5em .2em var(--n${focus})`
                  : "",
                animation: false ? "flash-red 1.5s linear" : "",
              }}
              className={"n" + e.value + (e.value === focus ? " selected" : "")}
              onClick={() => chooseTile(e)}>
              {e.value}
            </button>
          ) : (
            <button className={getName(e)} onClick={() => chooseTile(e, index)}>
              {nums.map((n: number) => (
                <div
                  className="note-number"
                  key={n - 1}
                  style={{
                    backgroundColor: e.notes[n - 1] ? `var(--n${n})` : "",
                  }}>
                  {e.notes[n - 1] ? n : ""}
                </div>
              ))}
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

function Numbers({
  focus,
  setFocus,
  selectNum,
  nums,
}: {
  focus: any;
  setFocus: any;
  selectNum: any;
  nums: number[];
}) {
  return (
    <div className="numbers">
      {nums.map((n) => (
        <button
          style={{
            backgroundColor:
              game.board.countNums[n - 1] > 0 ? `var(--n${n})` : "transparent",
            color: game.board.countNums[n - 1] === 0 ? `var(--n${n})` : "white",
            boxShadow:
              boxShadow && n === focus ? `0 0 1.5em .1em var(--n${n})` : "",
          }}
          key={n}
          className={
            "n" +
            n +
            (n === focus ? " selected" : "") +
            (n === focus ? " complete" : "")
          }
          onClick={() => selectNum(n)}>
          {n}
        </button>
      ))}
    </div>
  );
}
