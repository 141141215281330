import Game from "./Components/Game";
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";
import { SaveBoardTest } from "./db/SaveBoard";


function App() {
  return (
    <div className="sudoku-game">
      <header>
      <SignedOut>
        <SignInButton />
      </SignedOut>
      <SignedIn>
        {false && process.env.NODE_ENV === "development" &&
          <SaveBoardTest/>
        }
        <div className="user-button-container">
          <UserButton />
        </div>
        <Game />
      </SignedIn>
    </header>
    </div>
  );
}

export default App;
