import { createClient } from "@libsql/client";
import { drizzle } from 'drizzle-orm/libsql';

export const client = createClient({
  url: process.env.REACT_APP_TURSO_URL ?? '',
  authToken: process.env.REACT_APP_TURSO_AUTH_TOKEN,
});

export const db = drizzle(client);
// const result = async () => { await db.select().from(users).all()}
